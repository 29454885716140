import {
    cloneElement,
    Children,
    FC,
    useState,
    useLayoutEffect,
    memo,
    ReactNode,
    isValidElement,
    ReactElement,
    PropsWithChildren,
} from 'react';

import { ASYNC_HIGHLIGHT } from 'src/components/Markup/AsyncHighlightProcessor';
import LINKIFY_EMAIL from 'src/components/Markup/LinkifyEmailProcessor';
import LINKIFY from 'src/components/Markup/LinkifyProcessor';
import NL2BR from 'src/components/Markup/Nl2BrProcessor';
import { NextFunction, Processor } from 'src/components/Markup/makrupTypes';

interface MarkupProps {
    /**
     * Набор обработчиков в формате `(next) => (string) => newChildren`.
     * Для каждой текстовой ноды в дереве `children` вызывается первый обработчик.
     * Он возвращает новый `children` (любой валидный для JSX вариант), применяя
     * `next` к строкам, которые должны пройти через другие обработчики в цепочке.
     *
     * Пример для `processors = [A, B, C]`:
     * ```
     * const C1 = C(identity) // C1 == (string) => result, внутри next == identity
     * const B1 = B(C1)       // B1 == (string) => result, внутри next == С1
     * const A1 = A(B1)       // A1 == (string) => result, внутри next == B1
     * return A1(string)      // A(B(C(identity)))(string)
     * ```
     *
     * Примеры обработчиков:
     * ```
     * // Заменяет «Е» на «Ё»
     * const yoProcessor = (next) => (string) => next(string.replace('е', 'ё').replace('Е', 'Ё'));
     *
     * // Добавляет после каждой обработанной строки её исходник
     * const sourceProcessor = (next) => (string) => [next(string), ' (source: ', string, ')'];
     * const sourceProcessor = (next) => (string) => [`${next(string)} (source: ${string})`];
     *
     * // Заменяет текст с переводами строк на набор `p`
     * const NlToPProcessor = (next) => (string) => string.split('\n').map((v, i) => <p key={i}>{next(v)}</p>);
     * ```
     */
    processors?: Processor[];
}

const identity: NextFunction = (arg) => arg;

export function processChildren(children: ReactNode, processor: NextFunction): ReactElement {
    return (
        <>
            {Children.toArray(children).map((child) => {
                if (typeof child === 'string') {
                    return processor(child);
                }
                if (isValidElement<{ children: ReactNode }>(child)) {
                    const newChildren = child.props.children ? processChildren(child.props.children, processor) : null;
                    return cloneElement(child, undefined, newChildren);
                }
                return null;
            })}
        </>
    );
}

const DEFAULT_PROCESSORS = [LINKIFY, LINKIFY_EMAIL, ASYNC_HIGHLIGHT, NL2BR];
/**
 * Рекурсивно обрабатывает текстовые ноды указанным набором процессоров.
 */
const Markup: FC<MarkupProps & PropsWithChildren> = ({ children, processors = DEFAULT_PROCESSORS }) => {
    const [composed, setComposed] = useState(false);
    useLayoutEffect(() => {
        setComposed(true);
    }, []);

    if (!composed || !children) {
        return <>{children}</>;
    }

    const composedProcessor = processors.reduceRight((next, current) => current(next), identity);
    return processChildren(children, composedProcessor);
};

export default memo(Markup);
